import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthProvider } from "./contexts/context";
import Main from "./pages/Main/Main";
import { Maintenance } from "./pages/Maintenance/Maintenance";

export default function App() {
  const maintenance = process.env.REACT_APP_MAINTENANCE;
  return maintenance == "true" ? (
    <Maintenance></Maintenance>
  ) : (
    <AuthProvider>
      <Router>
        <Switch>
          <Route path="/">
            <Main />
          </Route>
        </Switch>
        <ToastContainer />
      </Router>
    </AuthProvider>
  );
}
