import React, { useEffect, useState } from 'react';

import { Table } from 'react-bootstrap';
import BFPagination from '../../../components/Pagination/Pagination';

import { commonService, toasterService } from '../../../services';

let PageSize = 10;

export default function LookupRun() {
  const [runs, setRuns] = useState([]);
  const [columns, setColumns] = useState([]);
  const [pageCount, setPageCount] = useState();
  const [loading, setLoader] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [q, setQ] = useState('');
  const [searchParam] = useState([
    'date_time',
    'runid',
    'user',
    'borrower_first_name',
    'borrower_last_name',
    'loan_purpose',
    'property_type',
    'number_of_units',
    'occupancy_type',
    'zip_code_short',
    'property_state'
  ]);
  const [sortField, setSortField] = useState('');
  const [order, setOrder] = useState('asc');
  // const columns = [
  //   { label: 'Date Time', accessor: 'date_time', sortable: true, isDate: true, width: 120 },
  //   { label: 'RunID', accessor: 'runid', sortable: true },
  //   { label: 'User', accessor: 'user', sortable: true },
  //   {
  //     label: 'Borrower First Name',
  //     accessor: 'borrower_first_name',
  //     sortable: true
  //   },
  //   {
  //     label: 'Borrower Last Name',
  //     accessor: 'borrower_last_name',
  //     sortable: true
  //   },
  //   { label: 'Price', accessor: 'price', sortable: true },
  //   {
  //     label: 'Target Yield',
  //     accessor: 'target_yield',
  //     sortable: true
  //   },
  //   {
  //     label: 'Heloan Amount',
  //     accessor: 'heloan_amount',
  //     sortable: true
  //   },
  //   {
  //     label: 'Heloan Rate',
  //     accessor: 'heloan_rate',
  //     sortable: true
  //   },
  //   {
  //     label: '1st Mortgage Amount',
  //     accessor: '1st_mortgage_amount',
  //     sortable: true
  //   },
  //   {
  //     label: '1st Mortgage Rate',
  //     accessor: '1st_mortgage_rate',
  //     sortable: true
  //   },
  //   {
  //     label: 'Original Interest Rate',
  //     accessor: 'original_interest_rate',
  //     sortable: true
  //   },
  //   {
  //     label: 'Original Upb',
  //     accessor: 'original_upb',
  //     sortable: true
  //   },
  //   {
  //     label: 'Original Loan Term',
  //     accessor: 'original_loan_term',
  //     sortable: true
  //   },
  //   {
  //     label: 'Origination Date',
  //     accessor: 'origination_date',
  //     sortable: true
  //   },
  //   {
  //     label: 'Original Loan To Value',
  //     accessor: 'original_loan_to_value',
  //     sortable: true
  //   },
  //   {
  //     label: 'Original Combined Loan to Value',
  //     accessor: 'original_combined_loan_to_value',
  //     sortable: true
  //   },
  //   {
  //     label: 'Number of Borrowers',
  //     accessor: 'number_of_borrowers',
  //     sortable: true
  //   },
  //   {
  //     label: 'Original Debt To Income Ratio',
  //     accessor: 'original_debt_to_income_ratio',
  //     sortable: true
  //   },
  //   {
  //     label: 'Borrower Credit Score at Origination',
  //     accessor: 'borrower_credit_score_at_origination',
  //     sortable: true
  //   },
  //   {
  //     label: 'Co-Borrower Credit Score at Origination',
  //     accessor: 'co_borrower_credit_score_at_origination',
  //     sortable: true
  //   },
  //   {
  //     label: 'Loan Purpose',
  //     accessor: 'loan_purpose',
  //     sortable: true
  //   },
  //   {
  //     label: 'Property Type',
  //     accessor: 'property_type',
  //     sortable: true
  //   },
  //   {
  //     label: 'No. of Units',
  //     accessor: 'number_of_units',
  //     sortable: true
  //   },
  //   {
  //     label: 'Occupancy Type',
  //     accessor: 'occupancy_type',
  //     sortable: true
  //   },
  //   {
  //     label: 'Zip Code',
  //     accessor: 'zip_code_short',
  //     sortable: true
  //   },
  //   {
  //     label: 'Property State',
  //     accessor: 'property_state',
  //     sortable: true
  //   },
  //   {
  //     label: 'First Payment Date',
  //     accessor: 'first_payment_date',
  //     sortable: true
  //   },
  //   {
  //     label: 'Loan Age',
  //     accessor: 'loan_age',
  //     sortable: true
  //   },
  //   {
  //     label: 'Home Value',
  //     accessor: 'home_value_calc',
  //     sortable: true
  //   },
  //   {
  //     label: 'HPI updated ltv',
  //     accessor: 'hpi_updated_ltv_calc',
  //     sortable: true
  //   },
  //   {
  //     label: 'HPI updated cltv',
  //     accessor: 'hpi_updated_cltv_calc',
  //     sortable: true
  //   },
  //   {
  //     label: 'HPI Vol',
  //     accessor: 'hpi_vol',
  //     sortable: true
  //   },
  //   {
  //     label: 'SATO',
  //     accessor: 'SATO',
  //     sortable: true
  //   },
  //   {
  //     label: 'Outstanding Balance bop',
  //     accessor: 'outstanding_balance_bop_calc',
  //     sortable: true
  //   },
  //   {
  //     label: 'Scheduled Interest Paid calc',
  //     accessor: 'scheduled_interest_paid_calc',
  //     sortable: true
  //   },
  //   {
  //     label: 'Scheduled Principal calc',
  //     accessor: 'scheduled_principal_calc',
  //     sortable: true
  //   },
  //   {
  //     label: 'Unscheduled Interest Paid calc',
  //     accessor: 'unscheduled_interest_paid_calc',
  //     sortable: true
  //   },
  //   {
  //     label: 'Unscheduled Principal calc',
  //     accessor: 'unscheduled_principal_calc',
  //     sortable: true
  //   },
  //   {
  //     label: 'Total Interest Paid calc',
  //     accessor: 'total_interest_paid_calc',
  //     sortable: true
  //   },
  //   {
  //     label: 'Total Principal calc',
  //     accessor: 'total_principal_calc',
  //     sortable: true
  //   },
  //   {
  //     label: 'Total Net Loss calc',
  //     accessor: 'total_net_loss_calc',
  //     sortable: true
  //   },
  //   {
  //     label: 'Recovery Calc before Adjust',
  //     accessor: 'recovery_calc_before_adjust',
  //     sortable: true
  //   },
  //   {
  //     label: 'fc Reference Age',
  //     accessor: 'fc_reference_age',
  //     sortable: true
  //   },
  //   {
  //     label: 'Recovery Calc',
  //     accessor: 'recovery_calc',
  //     sortable: true
  //   },
  //   {
  //     label: 'total Cashflow Calc',
  //     accessor: 'total_cashflow_calc',
  //     sortable: true
  //   },
  //   {
  //     label: 'Accrued Interest Calc',
  //     accessor: 'accrued_interest_calc',
  //     sortable: true
  //   },
  //   {
  //     label: 'Accrued Interest calc All dq status',
  //     accessor: 'accrued_interest_calc_all_dq_status',
  //     sortable: true
  //   },
  //   {
  //     label: 'Delinquent Balance calc',
  //     accessor: 'delinquent_balance_calc',
  //     sortable: true
  //   },
  //   {
  //     label: 'Def Balance calc',
  //     accessor: 'def_balance_calc',
  //     sortable: true
  //   },
  //   {
  //     label: 'Outstanding Balance eop calc',
  //     accessor: 'outstanding_balance_eop_calc',
  //     sortable: true
  //   },
  //   {
  //     label: 'Cum Sched Prin calc',
  //     accessor: 'cum_sched_prin_calc',
  //     sortable: true
  //   },
  //   {
  //     label: 'cum sched prin % calc',
  //     accessor: 'cum_sched_prin_%_calc',
  //     sortable: true
  //   },
  //   {
  //     label: 'cum vpr calc',
  //     accessor: 'cum_vpr_calc',
  //     sortable: true
  //   },
  //   {
  //     label: 'cum vpr % calc',
  //     accessor: 'cum_vpr_%_calc',
  //     sortable: true
  //   },
  //   {
  //     label: 'monthly vpr % calc',
  //     accessor: 'monthly_vpr_%_calc',
  //     sortable: true
  //   },
  //   {
  //     label: 'cum dq calc',
  //     accessor: 'cum_dq_calc',
  //     sortable: true
  //   },
  //   {
  //     label: 'cum dq % calc',
  //     accessor: 'cum_dq_%_calc',
  //     sortable: true
  //   },
  //   {
  //     label: 'cum prin cdr calc',
  //     accessor: 'cum_prin_cdr_calc',
  //     sortable: true
  //   },
  //   {
  //     label: 'cum prin cdr % calc',
  //     accessor: 'cum_prin_cdr_%_calc',
  //     sortable: true
  //   },
  //   {
  //     label: 'monthly prin cdr % calc',
  //     accessor: 'monthly_prin_cdr_%_calc',
  //     sortable: true
  //   },
  //   {
  //     label: 'cum cdr calc',
  //     accessor: 'cum_cdr_calc',
  //     sortable: true
  //   },
  //   {
  //     label: 'cum cdr % calc',
  //     accessor: 'cum_cdr_%_calc',
  //     sortable: true
  //   },
  //   {
  //     label: 'cum sev calc',
  //     accessor: 'cum_sev_calc',
  //     sortable: true
  //   },
  //   {
  //     label: 'cum sev % calc',
  //     accessor: 'cum_sev_%_calc',
  //     sortable: true
  //   },
  //   {
  //     label: 'cum rec calc',
  //     accessor: 'cum_rec_calc',
  //     sortable: true
  //   },
  //   {
  //     label: 'cum rec % calc',
  //     accessor: 'cum_rec_%_calc',
  //     sortable: true
  //   },
  //   {
  //     label: 'cum total cashflow calc',
  //     accessor: 'cum_total_cashflow_calc',
  //     sortable: true
  //   },
  //   {
  //     label: 'cum return % calc',
  //     accessor: 'cum_return_%_calc',
  //     sortable: true
  //   },
  //   {
  //     label: 'severity % calc',
  //     accessor: 'severity_%_calc',
  //     sortable: true
  //   },
  //   {
  //     label: 'severity % home value calc',
  //     accessor: 'severity_%_home_value_calc',
  //     sortable: true
  //   },
  //   {
  //     label: 'foreclosure timeline calc',
  //     accessor: 'foreclosure_timeline_calc',
  //     sortable: true
  //   }
  // ];

  useEffect(() => {
    if (!q) {
      getLookupData(currentPage, PageSize);
    }
  }, [q]);

  function getLookupData(currentPage, PageSize) {
    setLoader((loading) => (loading = true));
    commonService
      .getLookupRuns(currentPage, PageSize)
      .then((data) => {
        let response = data.data;
        setRuns(response.data);
        setColumns(response.columns);
        setPageCount(Math.floor(response.totalCount / response.pageSize));
        setTotalCount(response.totalCount);
        setLoader((loading) => (loading = false));
      })
      .catch((error) => {
        toasterService.error(error.response.data.message);
      });
  }

  const search = (e) => {
    e.preventDefault(true);
    e.stopPropagation(true);
    setLoader((loading) => (loading = true));
    commonService
      .getLookupRuns(1, PageSize, q)
      .then((data) => {
        let response = data.data;
        setRuns(response.data);
        setPageCount(Math.floor(response.totalCount / response.pageSize));
        setTotalCount(response.totalCount);
        setLoader((loading) => (loading = false));
      })
      .catch((error) => {
        toasterService.error(error.response.data.message);
      });
  };

  const handleSortingChange = (accessor) => {
    const sortOrder =
      accessor === sortField && order === 'asc' ? 'desc' : 'asc';
    setSortField(accessor);
    setOrder(sortOrder);
    handleSorting(accessor, sortOrder);
  };

  const handleSorting = (sortField, sortOrder) => {
    if (sortField) {
      const sorted = [...runs].sort((a, b) => {
        a[sortField] = a[sortField] ? a[sortField] : '';
        b[sortField] = b[sortField] ? b[sortField] : '';
        return (
          a[sortField].toString().localeCompare(b[sortField].toString(), 'en', {
            numeric: true
          }) * (sortOrder === 'asc' ? 1 : -1)
        );
      });
      setRuns(sorted);
    }
  };
  const getValue = (run, field) => {
    if (field.isDate) {
      let date = run[field.accessor].split('-');
      return new Date(
        date[0],
        date[1],
        date[2],
        date[3],
        date[4],
        date[5]
      ).toLocaleString();
    }
    return run[field.accessor] ? run[field.accessor] : '';
  };
  return (
    <div className="row">
      <div className="mb-3">
        <h4>Lookup Runs</h4>
      </div>
      <div className="col-12">
        <div className="search-wrapper">
          <button onClick={search}>
            <i className="bi-search" />
          </button>
          <label htmlFor="search-form">
            <input
              type="search"
              name="search-form"
              id="search-form"
              className="form-control"
              placeholder="Search runs"
              value={q}
              onChange={(e) => setQ(e.target.value)}
            />
          </label>
        </div>
      </div>

      <div className="col-12">
        <Table striped bordered hover responsive className='lookup-table'>
          <thead>
            <tr>
              {columns.map(({ label, accessor, sortable, width }) => {
                const cl = sortable ? (
                  sortField === accessor && order === 'asc' ? (
                    <i className="bi bi-sort-down-alt"></i>
                  ) : sortField === accessor && order === 'desc' ? (
                    <i className="bi bi-sort-down"></i>
                  ) : (
                    <i className="bi bi-sort-down-alt"></i>
                  )
                ) : (
                  ''
                );
                return (
                  <th
                    key={accessor}
                    style={{ width: width }}
                    onClick={
                      sortable ? () => handleSortingChange(accessor) : null
                    }
                  >
                    {label}
                    {cl}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {runs.length == 0 && !loading ? (
              <tr>
                <td align="center" colSpan="9">
                  No data available
                </td>
              </tr>
            ) : (
              <></>
            )}
            {loading ? (
              <tr>
                <td align="center" colSpan="10">
                  Loading
                </td>
              </tr>
            ) : (
              runs.map((run) => {
                return (
                  <tr key={run.runid}>
                    {columns.map((field) => {
                      return (
                        <td
                          key={`${field.accessor}-${run.runid}-${run.date_time}`}
                        >
                          {getValue(run, field)}
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            )}
          </tbody>
        </Table>
        {runs.length > 0 && totalCount ? (
          <BFPagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={totalCount}
            pageSize={PageSize}
            onPageChange={(page) => {
              getLookupData(page, PageSize);
              setCurrentPage(page);
            }}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
