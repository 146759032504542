import React, { useEffect } from 'react'
import $ from 'jquery';
import Inputmask from "inputmask";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

const FloatInput = ({ field_id, field_label, field_placeholder, field_value }) => {
    const { register, errors } = useFormContext();

    useEffect(() => {
        // Update the document title using the browser API
        // console.log("FloatInput Component loaded");
        Inputmask().mask(document.querySelectorAll("input"));
    });


    // const { handleChange } = useFormContext()
    return (
        <div className="mb-3">
            <label htmlFor={field_id} className="form-label">{field_label}</label>
            <input id="" type="text" className="form-control" id={field_id}
                data-inputmask="'alias': 'numeric', 'groupSeparator': ',', 'digits': 2, 'digitsOptional': false, 'placeholder': '0'"
                inputMode="decimal"
                placeholder={field_placeholder ? field_placeholder : ''}
                key={field_id}
                {...register(field_id, {
                    required: "This is a required field"
                })}
            />
            <ErrorMessage
                errors={errors}
                name={field_id}
                render={({ message }) => <p className="fb-error-message">{message}</p>}
            />
        </div>
    )
}

export default FloatInput