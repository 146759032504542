import React, { useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ErrorMessage } from "@hookform/error-message";
import { Controller, useFormContext } from "react-hook-form";

const DateField = ({ field_id, field_label, field_placeholder, field_value, validation }) => {
    const { register, errors, control } = useFormContext();
    const startDate = new Date();

    return (
        <div className="mb-3">
            <label htmlFor={field_id} className="form-label">{field_label}</label>
            <Controller
                control={control}
                name={field_id}
                valueName="selected" // DateSelect value's name is selected
                onChange={([selected]) => selected}
                defaultValue={startDate}
                render={({ field }) => (
                    <DatePicker
                        {...register(field_id)}
                        dateFormat={validation ? JSON.parse(validation).format : ""}
                        onChange={(date) => field.onChange(date)}
                        selected={field.value ? field.value : startDate}
                        yearDropdownItemNumber={100}
                        scrollableYearDropdown={true}
                        showYearDropdown
                        showMonthDropdown
                        ref={{ current: {} }}
                        className="form-control" />
                )}
                rules={{ required: "This field is required" }}
            />
            <ErrorMessage
                errors={errors}
                name={field_id}
                render={({ message }) => <p className="fb-error-message">{message}</p>}
            />
        </div>
    )
}

export default DateField