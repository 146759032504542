import React, { useState } from 'react';
import { Nav } from 'react-bootstrap';

export function AdminMenu() {
  const [selectedKey, setSelectedKey] = useState(window.location.pathname);
  return (
    <Nav className="me-auto" defaultActiveKey={selectedKey}>
      <Nav.Link href="/portal/user-management/users">User Activity</Nav.Link>
      <Nav.Link href="/portal/user-management/approval">User Approval</Nav.Link>
      <Nav.Link href="/portal/user-management/lookup-run">Lookup Runs</Nav.Link>
      {/* <Nav.Link href="/portal/activity-log">Activity Log</Nav.Link> */}
      {/* <Nav.Link href="/portal/reports">Admin Reports</Nav.Link> */}
    </Nav>
  );
}
