import { BehaviorSubject } from 'rxjs';

import { fetchWrapper } from '../helpers/index';

const userSubject = new BehaviorSubject(null);
const baseUrl = process.env.REACT_APP_SERVER_URL;

export const accountService = {
    login,
    logout,
    register,
    getOtpForRegistration,
    getOtpForLogin,
    forgotPassword,
    resetPassword,
    getAllUsers,
    getUserById,
    update,
    delete: _delete,
    user: userSubject.asObservable(),
    get userValue() { return userSubject.value }
};

function login(email, password) {
    return fetchWrapper.post(`${baseUrl}/users/login`, { email, password })
        .then(user => {
            // publish user to subscribers and start timer to refresh token
            userSubject.next(user);
            return user;
        });
}

function logout() {
    // revoke token, stop refresh timer, publish null to user subscribers and redirect to login page
    fetchWrapper.post(`${baseUrl}/logout`, {});
    userSubject.next(null);
}

function register(params) {
    return fetchWrapper.post(`${baseUrl}/users/register`, params);
}

function getOtpForRegistration(token) {
    return fetchWrapper.post(`${baseUrl}/api/otp`, { token });
}

function getOtpForLogin(token) {
    return fetchWrapper.post(`${baseUrl}/users/login`, { token });
}

function forgotPassword(email) {
    return fetchWrapper.post(`${baseUrl}/forgot-password`, { email });
}

function resetPassword({ token, password, confirmPassword }) {
    return fetchWrapper.post(`${baseUrl}/reset-password`, { token, password, confirmPassword });
}

function getAllUsers() {
    return fetchWrapper.get(baseUrl);
}

function getUserById(id) {
    return fetchWrapper.get(`${baseUrl}/${id}`);
}

function update(id, params) {
    return fetchWrapper.put(`${baseUrl}/${id}`, params)
        .then(user => {
            // update stored user if the logged in user updated their own record
            if (user.id === userSubject.value.id) {
                // publish updated user to subscribers
                user = { ...userSubject.value, ...user };
                userSubject.next(user);
            }
            return user;
        });
}

// prefixed with underscore because 'delete' is a reserved word in javascript
function _delete(id) {
    return fetchWrapper.delete(`${baseUrl}/${id}`)
        .then(x => {
            // auto logout if the logged in user deleted their own record
            if (id === userSubject.value.id) {
                logout();
            }
            return x;
        });
}
