import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import "./Signup.css";
import logo from "../../assets/images/Button_Finance_Logo.png";

import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { commonService, toasterService } from "../../services/index";
import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

export function Signup() {
  const history = useHistory();
  const validationSchema = Yup.object().shape({
    FName: Yup.string().required("Please enter first name"),
    ORG_Name: Yup.string().required("Please enter company name"),
    LName: Yup.string().required("Please enter last name"),
    email: Yup.string()
      .required("Please enter your email")
      .email("Please enter a valid email"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const [userSubmitted, submitUserFn] = useState(false);

  const onSubmit = (data) => {
    // console.log(data);
    // userDetailsFn(data);
    // submitUserFn(true);
    commonService
      .register(data)
      .then((res) => {
        // console.log("User registered! Sending mail for Passcode...", data);
        // submitUserFn(true);
        toasterService.success(
          "Your sign-up request has been successfully submitted. We will reach out shortly to let you know when it is approved.",
          {
            hideProgressBar: true,
            autoClose: false,
          }
        );
        history.push("/login");
      })
      .catch((error) => {
        toasterService.error(error.response.data.message);
      });
  };

  return (
    <div className="ip-section">
      <Container>
        <Row className="mb-4">
          <Col xs lg="12" className="text-center">
            <img
              src={logo}
              width="140"
              className="d-inline-block align-top"
              alt="Button Finance logo"
            />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs lg="6" md="12">
            <div className="wrap d-md-flex">
              {/* <div style={{ backgroundImage: `url(${login_bg})` }} className="login-bg"></div> */}
              <div className="w-100 p-4 p-md-5">
                {!userSubmitted && (
                  <>
                    <div className="w-100">
                      <h3 className="mb-4">Sign up</h3>
                    </div>

                    <Form onSubmit={handleSubmit(onSubmit)}>
                      <Form.Group className="mb-2">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          type="text"
                          {...register("FName")}
                          placeholder="First Name"
                        />
                        <ErrorMessage
                          errors={errors}
                          name="FName"
                          render={({ message }) => (
                            <p className="fb-error-message">{message}</p>
                          )}
                        />
                      </Form.Group>
                      <Form.Group className="mb-2">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          type="text"
                          {...register("LName")}
                          placeholder="Last Name"
                        />
                        <ErrorMessage
                          errors={errors}
                          name="LName"
                          render={({ message }) => (
                            <p className="fb-error-message">{message}</p>
                          )}
                        />
                      </Form.Group>
                      <Form.Group className="mb-2">
                        <Form.Label>Company Name</Form.Label>
                        <Form.Control
                          type="text"
                          {...register("ORG_Name")}
                          placeholder="Company Name"
                        />
                        <ErrorMessage
                          errors={errors}
                          name="ORG_Name"
                          render={({ message }) => (
                            <p className="fb-error-message">{message}</p>
                          )}
                        />
                      </Form.Group>
                      <Form.Group className="mb-2">
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control
                          type="email"
                          {...register("email")}
                          placeholder="Email Address"
                        />
                        <ErrorMessage
                          errors={errors}
                          name="email"
                          render={({ message }) => (
                            <p className="fb-error-message">{message}</p>
                          )}
                        />
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Button
                          type="submit"
                          className="w-100"
                          variant="primary"
                        >
                          Sign Up
                        </Button>
                      </Form.Group>
                      <div className="text-center">
                        Already a member? <Link to="/login">Sign In</Link>
                      </div>
                    </Form>
                  </>
                )}

                {/* {userSubmitted && <VerifyOtp mode="SIGNUP" user={userDetails} />} */}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
