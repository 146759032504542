import { Table } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import './ActivityLog.css';

export function ActivityLog() {
    return (
        <div>
            <div className="mb-3">
                <h4>Activity Log</h4>
                <p className="text-muted">View log of user activity</p>
            </div>
            
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Email</th>
                        <th>Login Timestamp</th>
                        <th>Logout Timestamp</th>
                        <th>IP Address</th>
                        <th>Activity</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>test@test.com</td>
                        <td>12/12/2021 12:00 PM</td>
                        <td>12/12/2021 12:00 PM</td>
                        <td>192.169.4.5</td>
                        <td>User has logged in</td>
                    </tr>
                </tbody>
            </Table>
        </div>
    )
}