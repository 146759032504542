import React from 'react';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './UserManagement.css';
import { UserLogs } from './UserLogs/UserLogs';
import { UsersList } from './UsersList/UsersList';
import LookupRun from './LookupRun/LookupRun';

export function UserManagement() {
  return (
    <div>
      <div>
        <Router>
          <div>
            <Switch>
              <Route
                path="/portal/user-management/users"
                component={UsersList}
              ></Route>
              <Route
                path="/portal/user-management/approval"
                component={UserLogs}
              ></Route>
              <Route
                path="/portal/user-management/lookup-run"
                component={LookupRun}
              ></Route>
            </Switch>
          </div>
        </Router>
      </div>
    </div>
  );
}
