import React, { useReducer } from "react";

let user = sessionStorage.getItem("currentUser")
    ? JSON.parse(sessionStorage.getItem("currentUser"))
    : "";
let token = sessionStorage.getItem("currentUser")
    ? JSON.parse(sessionStorage.getItem("currentUser")).token
    : "";

let role = sessionStorage.getItem("currentUser")
    ? JSON.parse(sessionStorage.getItem("currentUser")).Role
    : "";

export const initialState = {
    userDetails: "" || user,
    token: "" || token,
    role: "" || role
};

export const AuthReducer = (initialState, action) => {
    switch (action.type) {
        case "LOGIN_SUCCESS":
            return {
                ...initialState,
                userDetails: action.payload,
                token: action.payload.token,
                role: action.payload.Role
            };
        case "LOGOUT":
            return {
                ...initialState,
                userDetails: "",
                token: ""
            };

        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};