import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import "./InterestLoanCalculator.css";
import { useForm, FormProvider } from "react-hook-form";
import Inputmask from "inputmask";
import { commonService, toasterService } from "../../services";
import { FormField } from "../../components/FormField/FormField";
//import { LoanCalculatorOutput } from "../LoanCalculatorOutput/LoanCalculatorOutput";
import { OutputReports } from "../OutputReports/OutputReports";
import { useAuthState } from "../../contexts/context";
import { Role } from "../../helpers";
import licensedStates from "../../constant/licensedState";

export function InterestLoanCalculator() {
  const [elements, setElements] = useState(null);
  const [selectedOption, setSelectedOption] = useState(true);
  const [loading, showLoader] = useState(false);
  const [formSuccess, setFormSuccess] = useState(false);
  const [reportSuccess, setReportSuccess] = useState(false);
  const [outputData, setOutputData] = useState(null);

  const methods = useForm();
  const userDetails = useAuthState();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const fields = elements ?? [];

  const validaForm = (data) => {
    if (
      !licensedStates.includes(data.property_state.value) &&
      userDetails.role === Role.External
    ) {
      toasterService.error(
        "Not licensed in {state}. Coming Soon!".replace(
          "{state}",
          data.property_state.label
        )
      );
      return false;
    }
    return true;
  };
  const onSubmit = (data) => {
    if (validaForm(data)) {
      commonService
        .submitInterestLoanForm(data)
        .then((data) => {
          setFormSuccess(true);
          getOutputReport(data.data.data, 3);
          window.scrollTo({ top: 0, behavior: "smooth" });
        })
        .catch((error) => {
          toasterService.error(error.response.data.message);
        });
    }
  };

  useEffect(() => {
    // Update the document title using the browser API
    // console.log("Currency Component loaded");
    getFormFields(true);
    setFormSuccess(false);
    setReportSuccess(false);
    // getOutputReport('interest_input_173_1683832173101', 3);
    //
  }, []);

  function getOutputReport(filename, type) {
    commonService
      .generateReport({ file: filename, type: type })
      .then((data) => {
        setOutputData(data.data);
        setFormSuccess((formSuccess) => (formSuccess = false));
        setReportSuccess((reportSuccess) => (reportSuccess = true));
      })
      .catch((error) => {
        toasterService.error(error.response.data.message, {
          hideProgressBar: true,
          autoClose: false,
        });
        setFormSuccess((formSuccess) => (formSuccess = false));
      });
  }

  function getFormFields(value) {
    showLoader(true);
    commonService
      .getFormFields("I", "e")
      .then((data) => {
        setElements(data.data);
        setSelectedOption((selectedOption) => (selectedOption = true));
        Inputmask().mask(document.querySelectorAll("input"));
        showLoader((loading) => (loading = false));
      })
      .catch((error) => {
        toasterService.error(error.response.data.message);
      });
  }

  return (
    <div>
      <div className="mb-3">
        <h4>Loan calculator</h4>
        {/* <p className="text-muted">Please fill the form for single loan report</p> */}
      </div>
      <div className="form-container">
        {formSuccess && !reportSuccess && (
          <div className="form-success">
            {/* <FontAwesomeIcon className="text-success fa-4x mb-3" icon={faCheckCircle} /> */}
            <h4 className="display-6 text-center">
              Processing. Please don't close this window. Can take up to 2
              minutes.
            </h4>
            <div className="progress mt-4">
              <div
                className="progress-bar-fb"
                style={{ background: "#337ab7" }}
              ></div>
            </div>
          </div>
        )}
        {!formSuccess && reportSuccess && (
          <>
            <a
              className="text-primary text-decoration-none"
              href="javascript:;"
              onClick={() => {
                setFormSuccess(false);
                setReportSuccess(false);
              }}
            >
              <i className="bi bi-arrow-left-circle"></i> Go back
            </a>

            <h3>
              <br /><br />Ready to submit?{" "}
              <a href="https://buttonfinance.encompasstpoconnect.com/" target="_blank">Click here</a>
              <br />
              <br />
            Rate includes 0.25% discount for loans above $100k, if applicable.
            <br />
            <br />
            Rate includes an additional 0.125% discount on 3-yr Draw HELOCs.
            <br />
            Correspondents will receive an additional 0.25% purchase premium on 3-yr Draw HELOCs, instead of the discount.
            <br />
            <br />
            </h3>

            <OutputReports outputData={outputData} isLC />
          </>
        )}

        <div hidden={formSuccess || reportSuccess}>
          <hr />
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              {loading && (
                <div key="loader" className="d-flex justify-content-center">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              )}
              {!loading && fields.length > 0 && (
                <div>
                  <Row>
                    {fields.map((field, i) => {
                      return (
                        <Col key={i} xs={12} md={12 / 2}>
                          <FormField key={i} field={field} />
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              )}

              <input
                className="btn btn-primary"
                type="submit"
                variant="primary"
                value="Submit"
              />
            </form>
          </FormProvider>
        </div>
      </div>
    </div>
  );
}
