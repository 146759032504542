import { Table } from "react-bootstrap";
import "./UsersList.css";
import { useEffect, useMemo, useState } from "react";
import { commonService, toasterService } from "../../../services";
import { useTable, useGlobalFilter, usePagination, useFilters, useSortBy } from 'react-table';
// import { format } from 'date-fns';

export function UsersList() {
  const [users, updateUsers] = useState([]);

  // const [q, setQ] = useState("");
  // const [searchParam] = useState([
  //   "FName",
  //   "LName",
  //   "email",
  //   "ORG_Name",
  //   "Role",
  //   "product_type",
  // ]);
  // const [filterParam, setFilterParam] = useState(["All"]);
  // const [sortField, setSortField] = useState("");
  // const [order, setOrder] = useState("asc");


  const COLUMNS = [
    { Header: "First Name", accessor: "FName", sortable: true, width: 125 },
    { Header: "Last Name", accessor: "LName", sortable: true, width: 120 },
    { Header: "Company", accessor: "ORG_Name", sortable: true },
    { Header: "Email", accessor: "email", sortable: true, width: 150 },
    { Header: "Joined on", accessor: "createdAt", sortable: true, width: 120, Cell: ({ value }) => { return new Date(value).toLocaleDateString() } },
    {
      Header: "Last Login", accessor: "Last_Login", sortable: true, width: 120,
      Cell: ({ value }) => {
        return value
          ? new Date(value).toLocaleDateString() +
          " " +
          new Date(value).toLocaleTimeString()
          : ""
      }
    },
    {
      Header: "Role", accessor: "Role", width: 120,
      Cell: (props) => {
        // console.log(props);
        return (
          <select
            onChange={(props) => updateUserRole(props)}
            data-user={props.row.original.USR_ID}
            defaultValue={props.value}
            className="form-control"
          >
            <option key={"a"} value="a">Admin</option>
            <option key={"e"} value="e">External</option>
            <option key={"i"} value="i">Internal</option>
          </select>
        )
      }
    },
    {
      Header: "Product Type", accessor: "product_type", width: 160,
      Cell: (props) => {
        // console.log(props);
        return (
          <select
            onChange={(props) => updateUserProduct(props)}
            data-user={props.row.original.USR_ID}
            defaultValue={props.value}
            className="form-control"
          >
            <option key={"ALL"} value="ALL">ALL</option>
            <option key={"HELOC"} value="HELOC">HELOC</option>
            <option key={"HELOAN"} value="HELOAN">HELOAN</option>
            <option key={"DEFAULT"} value="DEFAULT">DEFAULT</option>
          </select>
        )
      }
    },
  ];

  const columns = useMemo(() => COLUMNS, []);

  const tableInstance = useTable({
    columns: columns,
    data: users,
    initialState: {
      pageSize: 10,
      pageIndex: 0
    }
  }, useFilters, useGlobalFilter, useSortBy, usePagination)

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state, setGlobalFilter, page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter } } = tableInstance


  useEffect(() => {
    _getUsers();
  }, []);

  function _getUsers() {
    commonService
      .getUsers()
      .then((data) => {
        updateUsers([...data.data]);
      })
      .catch((error) => {
        toasterService.error(error.response.data.message);
      });
  }

  // function search(items) {
  //   return items.filter((item) => {
  //     if (item.product_type == filterParam) {
  //       return searchParam.some((newItem) => {
  //         return (
  //           item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
  //         );
  //       });
  //     } else if (filterParam == "All") {
  //       return searchParam.some((newItem) => {
  //         return (
  //           item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
  //         );
  //       });
  //     }
  //   });
  // }

  const updateUserRole = (ev) => {
    console.log(ev);
    let data = { Role: ev.target.value };
    let userId = ev.target.dataset.user;
    commonService
      .updateUser(userId, data)
      .then((data) => {
        toasterService.success("User role has been updated.");
        _getUsers();
      })
      .catch((error) => {
        toasterService.error(error.response.data.message);
      });
  };

  const updateUserProduct = (ev) => {
    let data = { product_type: ev.target.value };
    let userId = ev.target.dataset.user;
    commonService
      .updateUser(userId, data)
      .then((data) => {
        toasterService.success("User product type has been updated.");
        _getUsers();
      })
      .catch((error) => {
        toasterService.error(error.response.data.message);
      });
  };

  // const handleSortingChange = (accessor) => {
  //   const sortOrder =
  //     accessor === sortField && order === "asc" ? "desc" : "asc";
  //   setSortField(accessor);
  //   setOrder(sortOrder);
  //   handleSorting(accessor, sortOrder);
  // };

  // const handleSorting = (sortField, sortOrder) => {
  //   if (sortField) {
  //     const sorted = [...users].sort((a, b) => {
  //       a[sortField] = a[sortField] ? a[sortField] : "";
  //       b[sortField] = b[sortField] ? b[sortField] : "";
  //       return (
  //         a[sortField].toString().localeCompare(b[sortField].toString(), "en", {
  //           numeric: true,
  //         }) * (sortOrder === "asc" ? 1 : -1)
  //       );
  //     });
  //     updateUsers(sorted);
  //   }
  // };

  return (
    <div>
      <div className="mb-3">
        <h4>User Activity</h4>
        {/* <p className="text-muted">Approve/Reject users to control their access to the portal</p> */}
      </div>
      <div className="filter-wrapper">
        <select
          className="form-control"
          onChange={(e) => {
            setGlobalFilter(e.target.value);
          }}
        >
          <option value="All">Filter By Product Type</option>
          <option value="HELOC">HELOC</option>
          <option value="HELOAN">HELOAN</option>
          <option value="DEFAULT">DEFAULT</option>
        </select>
      </div>
      <div className="search-wrapper">
        <i className="bi-search"></i>
        <label htmlFor="search-form">
          <input
            type="search"
            name="search-form"
            id="search-form"
            className="form-control"
            placeholder="Search users"
            value={globalFilter || ""}
            onChange={e => setGlobalFilter(e.target.value)}
          />
        </label>
      </div>
      {/* <Table striped bordered hover>
        <thead>
          <tr>
            {columns.map(({ label, accessor, sortable, width }) => {
              const sortIcon = sortable ? (
                sortField === accessor && order === "asc" ? (
                  <i className="bi bi-sort-down-alt"></i>
                ) : sortField === accessor && order === "desc" ? (
                  <i className="bi bi-sort-down"></i>
                ) : (
                  <i className="bi bi-sort-down-alt"></i>
                )
              ) : (
                ""
              );
              return (
                <th
                  key={accessor}
                  style={{ width: width }}
                  onClick={
                    sortable ? () => handleSortingChange(accessor) : null
                  }
                >
                  {label}
                  {sortIcon}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          <tr hidden={users.length > 0}>
            <td align="center" colSpan="7">
              No records available
            </td>
          </tr>
          {search(users).map((user) => {
            return (
              <tr key={user.USR_ID}>
                <td>{user.FName}</td>
                <td>{user.LName}</td>
                <td>{user.ORG_Name}</td>
                <td>{user.email}</td>

                <td>{new Date(user.createdAt).toLocaleDateString()}</td>
                <td>
                  {user.Last_Login
                    ? new Date(user.Last_Login).toLocaleDateString() +
                    " " +
                    new Date(user.Last_Login).toLocaleTimeString()
                    : ""}
                </td>
                <td>
                  <select
                    data-user={user.USR_ID}
                    onChange={updateUserRole}
                    defaultValue={user.Role}
                    className="form-control"
                  >
                    <option value="a">Admin</option>
                    <option value="e">External</option>
                    <option value="i">Internal</option>
                  </select>
                </td>
                <td>
                  <select
                    data-user={user.USR_ID}
                    onChange={updateUserProduct}
                    defaultValue={user.product_type}
                    className="form-control"
                  >
                    <option value="ALL">ALL</option>
                    <option value="HELOC">HELOC</option>
                    <option value="HELOAN">HELOAN</option>
                    <option value="DEFAULT">DEFAULT</option>
                  </select>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table> */}

      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={
                    column.isSorted
                      ? column.isSortedDesc
                        ? "desc"
                        : "asc"
                      : ""
                  }
                >
                  <span>{column.render('Header')}</span>
                  {column.isSorted
                    ? column.isSortedDesc
                      ? <i className="bi bi-sort-down"></i>
                      : <i className="bi bi-sort-down-alt"></i>
                    : ""}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="pagination-container justify-content-center align-items-center my-4 gap-4">
        <span>
          Page&nbsp;
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <ul className="pagination m-0">
          <li className="page-item" >
            <a disabled={!canPreviousPage} onClick={() => gotoPage(0)} className="page-link"><i className="bi-chevron-double-left"></i></a>
          </li>{" "}
          <li className="page-item" >
            <a onClick={() => previousPage()} disabled={!canPreviousPage} className="page-link"><i className="bi-chevron-left"></i></a>
          </li>{" "}
          <li className="page-item" >
            <a className="page-link" onClick={() => nextPage()} disabled={!canNextPage}><i className="bi-chevron-right"></i></a>
          </li>{" "}
          <li className="page-item">
            <a onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage} className="page-link"><i className="bi-chevron-double-right"></i></a>
          </li>{" "}
        </ul>
        <select
          value={pageSize}
          onChange={(e) => setPageSize(Number(e.target.value))}
        >
          {[10, 50, 100].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}
