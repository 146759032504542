import { Container, Row, Col, Form, FloatingLabel, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { commonService, toasterService } from '../../services/index';
import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useHistory } from "react-router-dom";
import logo from '../../assets/images/Button_Finance_Logo.png';

export function ForgotPassword() {
    const history = useHistory();

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required('Please enter your email')
            .email('Please enter a valid email')
    });

    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });

    useEffect(() => {
    });

    const onSubmit = (email) => {
        commonService.forgotPassword(email)
            .then((data) => {
                toasterService.success("Email sent successfully");
                history.push("/login");
            })
            .catch(error => {
                toasterService.error(error.response.data.message);
            });

    };

    return (
        <Container className="pt-5">
            <Row className="mb-4">
                <Col xs lg="12" className="text-center">
                    <img
                        src={logo}
                        width="140"
                        className="d-inline-block align-top"
                        alt="Button Finance logo"
                    />
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col>
                    <div className="wrap w-50 m-auto">
                        <div className="p-4 p-md-5">
                            <div className="w-100">
                                <div className="mb-4">
                                    <h3>Forgot Password</h3>
                                    <p className="m-0 text-muted"><small>Please enter your registered email address. Your password will be mailed to you shortly.</small></p>
                                </div>
                            </div>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control name="otp" type="text"
                                        placeholder="Enter Email" {...register("email")} />
                                    <ErrorMessage
                                        errors={errors}
                                        name="email"
                                        render={({ message }) => <p className="fb-error-message">{message}</p>}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Button type="submit" className="w-100" variant="primary">Verify</Button>
                                </Form.Group>
                                <div className="text-center">
                                    <Link to="/login">Back to login</Link>
                                </div>
                            </Form>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}