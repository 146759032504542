import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { Navbar, Container, Nav } from "react-bootstrap";
import { AdminMenu } from "../../components/AdminMenu/AdminMenu";
import logo from "../../assets/images/Button_Finance_Logo.png";
// import '../../assets/css/sidebar.css';
import "./Portal.css";
import { UserManagement } from "../UserManagement/UserManagement";
import { ActivityLog } from "../ActivityLog/ActivityLog";
import { UserMenu } from "../../components/UserMenu/UserMenu";
import { InterestLoanCalculator } from "../InterestLoanCalculator/InterestLoanCalculator";
import { SingleLoan } from "../SingleLoan/SingleLoan";
import { PriceLoanPortfolio } from "../PriceLoanPortfolio/PriceLoanPortfolio";
import { OutputReports } from "../OutputReports/OutputReports";
import { useHistory } from "react-router-dom";
import { useAuthDispatch, useAuthState } from "../../contexts/context";
import { Role } from "../../helpers";

export function Portal() {
  const history = useHistory();
  const dispatch = useAuthDispatch(); // read dispatch method from context
  const userDetails = useAuthState(); //read user details from context

  const handleLogout = () => {
    dispatch({ type: "LOGOUT" });
    sessionStorage.removeItem("currentUser");
    sessionStorage.removeItem("token");
    history.push("/login"); //navigate to logout page on logout
  };

  const changePassword = () => {
    history.push("/change-password");
  };

  const gotoHome = () => {
    if (userDetails.role !== Role.External) {
      history.push("/portal/price-single-loan");
    } else {
      history.push("/portal/loan-calculator");
    }
  };

  const getUserMenu = () => {
    if (userDetails.role === Role.Admin) {
      return <AdminMenu />;
    } else if (userDetails.role === Role.Internal) {
      return <UserMenu />;
    } else {
      return (
        <Nav className="me-auto" defaultActiveKey={window.location.pathname}>
          <Nav.Link
            className="text-dark fb-menu-item"
            href="/portal/loan-calculator"
          >
            Loan Calculator
          </Nav.Link>
        </Nav>
      );
    }
  };

  return (
    <main>
      <Navbar collapseOnSelect expand="lg" className="bg-white">
        <Container>
          <Navbar.Brand>
            <div
              onClick={gotoHome}
              className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-decoration-none"
            >
              <img
                src={logo}
                width="110"
                className="d-inline-block align-top"
                alt="Button Finance logo"
              />
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav" className="ms-5">
            {getUserMenu()}
            <Nav>
              <Navbar.Text className="border-end pe-2 nav-link">
                Signed in as:{" "}
                <b className="fw-semibold text-dark">
                  {userDetails.userDetails.FName}{" "}
                  {userDetails.userDetails.LName}
                </b>
              </Navbar.Text>

              <Nav.Link
                className="text-danger"
                eventKey={2}
                onClick={handleLogout}
              >
                Logout
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <section className="container mb-4">
        <Router>
          <div>
            <Switch>
              <Route
                exact
                path="/portal"
                render={() => {
                  if (userDetails.role == Role.Admin) {
                    return <Redirect to="/portal/user-management/approval" />;
                  } else if (userDetails.role == Role.Internal) {
                    return <Redirect to="/portal/price-single-loan" />;
                  } else {
                    return <Redirect to="/portal/loan-calculator" />;
                  }
                }}
              ></Route>
              <Route
                path="/portal/user-management"
                component={UserManagement}
              ></Route>
              <Route
                path="/portal/activity-log"
                component={ActivityLog}
              ></Route>
              {/* <Route path="/portal/interest-loan-calculator" component={InterestLoanCalculator}></Route> */}
              <Route
                path="/portal/price-single-loan"
                component={SingleLoan}
              ></Route>
              <Route
                path="/portal/loan-calculator"
                component={InterestLoanCalculator}
              ></Route>
              <Route
                path="/portal/price-loan-portfolio"
                component={PriceLoanPortfolio}
              ></Route>
              <Route
                path="/portal/output-reports"
                component={OutputReports}
              ></Route>
              <Route path="/portal/reports">
                <h4>Admin Reports</h4>
              </Route>
            </Switch>
          </div>
        </Router>
      </section>
      <footer>
        <div className="fb-footer">
          <div className="top-footer">
            <div className="container">
              <h6>
                <span style={{ color: "#2e2e2e" }}>
                  {" "}
                  © 2023 Button Finance, Inc.
                  <br />
                  <a
                    href="https://www.nmlsconsumeraccess.org/EntityDetails.aspx/COMPANY/1943460"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    NMLS Consumer Access | Company NMLS # 1943460
                  </a>
                  <br />
                  Equal Housing Lender
                </span>
              </h6>
              {/* <div style={{ textAlign: "right", fontSize: 10 }}>Publish date: 7/2/2022 10:26 PM</div> */}
            </div>
          </div>
          <div className="bottom-footer">
            <div className="container">
              The content on this web site is provided for general information
              purposes only and does not constitute legal or other professional
              advice or an opinion of any kind. Users of this web site are
              advised to seek specific legal or financial advice regarding any
              specific legal issues. Button Finance, Inc. does not warrant or
              guarantee the quality, accuracy or completeness of any information
              on this web site. You should consult a tax advisor regarding the
              deductibility of interest and charges to your Home Equity Loan. We
              and our service providers use cookies to provide a secure
              authentication process. For more information, see our privacy
              policy and terms of services pages.
            </div>
          </div>
        </div>
      </footer>
    </main>
  );
}
