import { Container, Row, Col, Form, FloatingLabel, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { commonService, toasterService } from '../../services/index';
import { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useHistory } from "react-router-dom";
import logo from '../../assets/images/Button_Finance_Logo.png';
import { useAuthState } from '../../contexts/context';

export function ChangePassword() {
    const history = useHistory();
    const authObject = useAuthState();

    const validationSchema = Yup.object().shape({
        current_password: Yup.string()
            .required('This field is required'),
        new_password: Yup.string()
            .required('This field is required'),
        confirm_password: Yup.string()
            .required('This field is required'),

    });

    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const onSubmit = (data) => {
        let userId = null
        if (authObject && authObject.userDetails) {
            userId = authObject.userDetails.USR_ID;
        }
        else {
            toasterService.error("User authentication failed. Please try logging in again.");
            return false;
        }

        commonService.changePassword(userId, data)
            .then((data) => {
                // console.log(data);
                toasterService.success("Password updated successfully!");
                history.push("/login");
            })
            .catch(error => {
                toasterService.error(error.response.data.message);
            });

    };

    return (
        <Container className="pt-5">
            <Row className="mb-4">
                <Col xs lg="12" className="text-center">
                    <img
                        src={logo}
                        width="140"
                        className="d-inline-block align-top"
                        alt="Button Finance logo"
                    />
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col>
                    <div className="wrap w-50 m-auto">
                        <div className="p-4 p-md-5">
                            <div className="w-100">
                                <div className="mb-4">
                                    <h3>Update Password</h3>
                                </div>
                            </div>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Current Password</Form.Label>
                                    <Form.Control name="current_password" type="password"
                                        placeholder="Enter Password" {...register("current_password")} />
                                    <ErrorMessage
                                        errors={errors}
                                        name="current_password"
                                        render={({ message }) => <p className="fb-error-message">{message}</p>}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>New Password</Form.Label>
                                    <Form.Control name="new_password" type="password"
                                        placeholder="Enter Password" {...register("new_password")} />
                                    <ErrorMessage
                                        errors={errors}
                                        name="new_password"
                                        render={({ message }) => <p className="fb-error-message">{message}</p>}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Confirm Password</Form.Label>
                                    <Form.Control name="confirm_password" type="password"
                                        placeholder="Enter Password" {...register("confirm_password")} />
                                    <ErrorMessage
                                        errors={errors}
                                        name="confirm_password"
                                        render={({ message }) => <p className="fb-error-message">{message}</p>}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Button type="submit" className="w-100" variant="primary">Update</Button>
                                </Form.Group>
                                <div className="text-center">
                                    <Link to="/portal">Back to portal</Link>
                                </div>
                            </Form>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}