import axiosInstance from '../utils/api';

const commonService = {
  getOTPForRegistration(data) {
    return axiosInstance.post('/otp', data);
  },

  register(data) {
    return axiosInstance.post('/users/register', data);
  },
  login(data) {
    return axiosInstance.post('/users/login', data);
  },
  forgotPassword(data) {
    return axiosInstance.post('users/forgot-password', data);
  },
  changePassword(userId, data) {
    return axiosInstance.patch(`users/${userId}/change-password`, data);
  },
  getUserLogs() {
    return axiosInstance.get('/users/log/');
  },
  getUsers() {
    return axiosInstance.get('/users/');
  },
  approveIPAddress(user, id) {
    return axiosInstance.patch(
      `/users/${user}/ip-requests/${id}/approve`,
      null
    );
  },
  disapproveIPAddress(user, id) {
    return axiosInstance.patch(
      `/users/${user}/ip-requests/${id}/disapprove`,
      null
    );
  },
  updateUserStatus(id) {
    return axiosInstance.patch(`/users/${id}/approve`, null);
  },
  updateUser(id, data) {
    return axiosInstance.patch(`/users/${id}/`, data);
  },
  getFormFields(type, role = 'i') {
    return axiosInstance.get(`/forms/get-data/${type}/${role}`);
  },
  getZipCodes(searchTerm) {
    return axiosInstance.get(`/forms/zip-codes/?zipCode=${searchTerm}`);
  },
  getAllStates() {
    return axiosInstance.get(`/forms/states`);
  },
  getStateByZip(zip) {
    return axiosInstance.get(`/forms/state/${zip}`);
  },
  submitPriceLoanForm(data) {
    return axiosInstance.post(`/forms/loan/price`, data);
  },
  submitInterestLoanForm(data) {
    return axiosInstance.post(`/forms/loan/interest`, data);
  },
  generateReport(data) {
    return axiosInstance.post(`/output/generate-report`, data);
  },
  uploadCSV(data) {
    return axiosInstance.post(`/forms/loan/upload`, data);
  },
  getLookupRuns(page, pagesize, query = '') {
    return axiosInstance.get(
      `/output/lookup-run?page=${page}&pagesize=${pagesize}&query=${query}`
    );
  }
};

const authService = {
  getToken() {
    const tokenString = sessionStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken;
  },

  saveToken(userToken) {
    sessionStorage.setItem('token', JSON.stringify(userToken));
    // setToken(userToken.token);
  }
};

export { commonService, authService };
