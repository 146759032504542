import React from 'react'
import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

const Input = ({ field_id, field_label, field_placeholder, field_value, validation }) => {
    const { register, errors } = useFormContext();
    const validationObj = validation ? JSON.parse(validation) : null;
    return (
        <div className="mb-3">
            <label htmlFor={field_id} className="form-label">{field_label}</label>
            <input type="text" className="form-control" id={field_id}
                // placeholder={field_placeholder ? field_placeholder : ''}
                key={field_id + "_key"}
                // onChange={event => handleChange(field_id, event)}
                {...register(field_id, {
                    required: { value: validationObj.required ? validationObj.required : false, message: "This is a required field" },
                })}
            />
            <ErrorMessage
                errors={errors}
                name={field_id}
                render={({ message }) => <p className="fb-error-message">{message}</p>}
            />
        </div>
    )
}

export default Input