import React, { isValidElement, useEffect, useState } from "react";
import $ from "jquery";
import Inputmask from "inputmask";
import { useFormContext, useWatch } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { HelperService } from "../../../services/helper.service";

const Integer = ({
  field_id,
  field_label,
  field_placeholder,
  default_value,
  validation,
}) => {
  const { register, errors, control, watch, getValues } = useFormContext();
  const validationObj = validation ? JSON.parse(validation) : null;
  const [value, setValue] = useState(null);
  const nob = useWatch({
    control,
    name: "number_of_borrowers",
    defaultValue: { label: "1", value: "1" },
  });
  useEffect(() => {
    // Update the document title using the browser API
    // console.log("Integer Component loaded");
    Inputmask().mask(document.querySelectorAll("input"));
  }, []);

  const checkMinMaxValue = (value) => {
    if (
      field_id == "borrower_credit_score_at_origination" ||
      field_id == "co_borrower_credit_score_at_origination"
    ) {
      if (
        (validationObj.required && value < validationObj.min_value) ||
        value > validationObj.max_value
      ) {
        return `Value must be between ${validationObj.min_value} and ${validationObj.max_value}`;
      }
      return true;
    }
  };
  const handleOnChange = (e) => {
    setValue(e.target.value);
  };
  // const { handleChange } = useFormContext()
  return (
    <div className="mb-3">
      <label htmlFor={field_id} className="form-label">
        {field_label}
      </label>
      <input
        type="number"
        className="form-control"
        id={field_id}
        inputMode="number"
        defaultValue={default_value}
        readOnly={
          field_id == "co_borrower_credit_score_at_origination" &&
            nob &&
            nob.value == "1"
            ? true
            : false
        }
        placeholder={field_placeholder ? field_placeholder : ""}
        key={field_id}
        {...register(field_id, {
          required: {
            value: validationObj.required ? validationObj.required : false,
            message: "This is a required field",
          },
          validate: HelperService.isNegative,
          // validate: checkMinMaxValue,
          onChange: handleOnChange,
        })}
      />
      {((field_id == "co_borrower_credit_score_at_origination" &&
        getValues("number_of_borrowers").value === "2") ||
        field_id == "borrower_credit_score_at_origination") &&
        value &&
        value < validationObj?.min_value && (
          <small className="form-text text-black">
            Minimum Credit Score must be {validationObj?.min_value}
          </small>
        )}
      <ErrorMessage
        errors={errors}
        name={field_id}
        render={({ message }) => <p className="fb-error-message">{message}</p>}
      />
    </div>
  );
};

export default Integer;
