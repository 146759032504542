import React, { useEffect, useState, useRef } from "react";
import Inputmask from "inputmask";
import { set, useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

const Currency = ({
  field_id,
  field_label,
  field_placeholder,
  field_value,
  validation,
}) => {
  const { register, errors, getValues } = useFormContext();
  const validationObj = validation ? JSON.parse(validation) : null;
  const [currencyValue, setcurrencyValue] = useState(null);

  useEffect(() => {
    // Update the document title using the browser API
    // console.log("Currency Component loaded");
    Inputmask().mask(document.querySelectorAll("input"));
  });

  function handleOnBlur(e) {
    if (e.currentTarget.value) {
      var price =
        Number(e.currentTarget.value.replace("$", "").replace(/,/g, "")) * 12;
      setcurrencyValue(
        price.toLocaleString("en-US", { style: "currency", currency: "USD" })
      );
    }
  }
  return (
    <div className="mb-3">
      <label htmlFor={field_id} className="form-label">
        {field_label}
      </label>
      <input
        type="text"
        className="form-control"
        id={field_id}
        data-inputmask="'alias': 'numeric', 'groupSeparator': ',', 'digits': 2, 'digitsOptional': false, 'prefix': '$ ', 'placeholder': '0'"
        inputMode="decimal"
        placeholder={field_placeholder ? field_placeholder : ""}
        onBlurCapture={(val) => handleOnBlur(val)}
        key={field_id}
        {...register(field_id, {
          required: {
            value: validationObj.required ? validationObj.required : false,
            message: "This is a required field",
          },
        })}
      />
      <small
        hidden={field_id != "combined_annual_income"}
        className="form-text text-black"
      >
        Annual Income: {currencyValue}
      </small>
      {field_id === "2ndmortgage_amount_requested" &&
      getValues("2ndmortgage_amount_requested") &&
      Number(
        getValues("2ndmortgage_amount_requested")
          ?.replace("$", "")
          .replace(/,/g, "")
      ) < validationObj?.min_value ? (
        <small>
          Minimum Loan Amount is{" "}
          {validationObj?.min_value.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </small>
      ) : (
        ""
      )}
      {field_id === "2ndmortgage_amount_requested" &&
      getValues("2ndmortgage_amount_requested") &&
      Number(
        getValues("2ndmortgage_amount_requested")
          ?.replace("$", "")
          .replace(/,/g, "")
      ) > validationObj?.max_value ? (
        <small>
          Maximum Loan Amount is{" "}
          {validationObj?.max_value.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </small>
      ) : (
        ""
      )}
      {field_id === "home_value" &&
      getValues("home_value") &&
      Number(getValues("home_value")?.replace("$", "").replace(/,/g, "")) <
        validationObj?.min_value ? (
        <small>
          Minimum Home Value must be{" "}
          {validationObj?.min_value.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </small>
      ) : (
        ""
      )}
      <ErrorMessage
        errors={errors}
        name={field_id}
        render={({ message }) => <p className="fb-error-message">{message}</p>}
      />
    </div>
  );
};

export default Currency;
