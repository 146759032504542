import React, { useState } from 'react';
import { Nav } from 'react-bootstrap';

export function UserMenu() {
    const [selectedKey, setSelectedKey] = useState(window.location.pathname);
    return (
        <Nav className="me-auto" defaultActiveKey={selectedKey}>
            {/* <Nav.Link href="/portal/interest-loan-calculator">Interest and Loan Calculator</Nav.Link> */}
            <Nav.Link className="text-dark fb-menu-item" href="/portal/price-single-loan">Price Single Loan</Nav.Link>
            <Nav.Link className="text-dark fb-menu-item" href="/portal/price-loan-portfolio">Price Loan Portfolio</Nav.Link>
            {/* <Nav.Link href="/portal/output-reports">Output Reports</Nav.Link> */}
        </Nav>
    )
}