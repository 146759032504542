import { toast } from "react-toastify";

export const toasterService = {
  success,
  error,
  info,
  warn,
  dismiss,
};

const toasterOptionsVar = {
  hideProgressBar: true,
  autoClose: 5000,
};

function success(message, toasterOptions = toasterOptionsVar) {
  toast.success(message, toasterOptions);
}

function error(message, toasterOptions = toasterOptionsVar) {
  return toast.error(message, toasterOptions);
}

function info(message) {
  toast.info(message, toasterOptionsVar);
}

function warn(message) {
  toast.warning(message, toasterOptionsVar);
}

function dismiss(toasterId) {
  toast.dismiss(toasterId);
}
