import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useHistory
} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuthState } from "../../contexts/context";
import { Portal } from "../Portal/Portal";
import { Login } from "../Login/Login";
import { ForgotPassword } from "../ForgotPassword/ForgotPassword";
import { Signup } from "../Signup/Signup";
import { ChangePassword } from "../ChangePassword/ChangePassword";

export default function Main() {
    const userDetails = useAuthState() //read user details from context
    const history = useHistory()
    if (!userDetails.token) {
        history.push('/login');
    }
    return (
        <Router>
            <div>
                <Switch>
                    <Route path="/portal">
                        <Portal />
                    </Route>
                    <Route path="/login">
                        <Login />
                    </Route>
                    <Route path="/forgot-password">
                        <ForgotPassword />
                    </Route>
                    <Route path="/change-password">
                        <ChangePassword />
                    </Route>
                    <Route path="/signup">
                        <Signup />
                    </Route>
                </Switch>
            </div>
        </Router>
    );
}
