import { Table } from "react-bootstrap";
import "./UserLogs.css";
import { useEffect, useMemo, useState } from "react";
import { useTable, useGlobalFilter, usePagination, useFilters, useSortBy } from 'react-table';
import { commonService, toasterService } from "../../../services";

export function UserLogs() {
  const [userLogs, updateUserLogs] = useState([]);
  const [selectedUser, setUser] = useState({});
  const [q, setQ] = useState("");
  const [searchParam] = useState([
    "FName",
    "LName",
    "email",
    "ORG_Name",
    "bf_Valid_IP.IP_Address",
    "bf_Valid_IP.updatedAt",
  ]);
  const [sortField, setSortField] = useState("");
  const [order, setOrder] = useState("asc");

  useEffect(() => {
    _getUsers();
  }, []);

  const COLUMNS = [
    { Header: "First Name", accessor: "FName", sortable: true, width: 125 },
    { Header: "Last Name", accessor: "LName", sortable: true, width: 120 },
    { Header: "Company", accessor: "ORG_Name", sortable: true },
    { Header: "Email", accessor: "email", sortable: true, width: 150 },
    {
      Header: "IP Address",
      accessor: "IP_Address",
      sortable: false,
      width: 120,
      Cell: (props) => {
        console.log(props);
        return props.row.original.bf_Valid_IP ? (<span>{props.row.original.bf_Valid_IP.IP_Address}</span>) : (<span></span>)
      }
    },
    {
      Header: "Approved", accessor: "Approval_Ind", sortable: false, width: 80,
      Cell: (props) => {
        console.log(props);
        return props.row.original.bf_Valid_IP ? (
          <label className="switch ">
            <input
              className="success"
              data-approved={props.row.original.Approval_Ind}
              data-ip={props.row.original.bf_Valid_IP.id}
              id={props.row.original.USR_ID}
              key={props.row.original.USR_ID}
              type="checkbox"
              defaultChecked={props.row.original.bf_Valid_IP.Status == "approved"}
              onChange={() => approveUser(props)}
              name={props.row.original.USR_ID}
            />
            <span className="slider round"></span>
          </label>
        ) : (<span></span>)
      }
    },
    {
      Header: "Requested On",
      accessor: "bf_Valid_IP.updatedAt",
      sortable: true,
      width: 150,
      Cell: ({ value }) => { return new Date(value).toLocaleString() }
    },
  ];

  const columns = useMemo(() => COLUMNS, []);

  const tableInstance = useTable({
    columns: columns,
    data: userLogs,
    initialState: {
      pageSize: 10,
      pageIndex: 0
    }
  }, useFilters, useGlobalFilter, useSortBy, usePagination)

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state, setGlobalFilter, page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter } } = tableInstance

  function search(items) {
    return items.filter((item) => {
      return searchParam.some((newItem) => {
        if (newItem.split(".").length > 1 && item["bf_Valid_IP"] != null) {
          let splitKeys = newItem.split(".");
          return (
            item[splitKeys[0]][splitKeys[1]]
              .toString()
              .toLowerCase()
              .indexOf(q.toLowerCase()) > -1
          );
        } else if (newItem.split(".").length == 1) {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        }
      });
    });
  }

  const handleSortingChange = (accessor) => {
    const sortOrder =
      accessor === sortField && order === "asc" ? "desc" : "asc";
    setSortField(accessor);
    setOrder(sortOrder);
    handleSorting(accessor, sortOrder);
  };

  const handleSorting = (sortField, sortOrder) => {
    if (sortField) {
      if (sortField.split(".").length > 1) {
        let splitKeys = sortField.split(".");
        const sorted = [...userLogs].sort((a, b) => {
          if (a[splitKeys[0]] && b[splitKeys[0]]) {
            a[splitKeys[0]][splitKeys[1]] = a[splitKeys[0]][splitKeys[1]]
              ? a[splitKeys[0]][splitKeys[1]]
              : "";
            b[splitKeys[0]][splitKeys[1]] = b[splitKeys[0]][splitKeys[1]]
              ? b[splitKeys[0]][splitKeys[1]]
              : "";
            return (
              a[splitKeys[0]][splitKeys[1]]
                .toString()
                .localeCompare(b[splitKeys[0]][splitKeys[1]].toString(), "en", {
                  numeric: true,
                }) * (sortOrder === "asc" ? 1 : -1)
            );
          }
        });
        updateUserLogs(sorted);
      } else if (sortField.split(".").length == 1) {
        const sorted = [...userLogs].sort((a, b) => {
          a[sortField] = a[sortField] ? a[sortField] : "";
          b[sortField] = b[sortField] ? b[sortField] : "";
          return (
            a[sortField]
              .toString()
              .localeCompare(b[sortField].toString(), "en", {
                numeric: true,
              }) * (sortOrder === "asc" ? 1 : -1)
          );
        });
        updateUserLogs(sorted);
      }
    }
  };

  function _getUsers() {
    commonService
      .getUserLogs()
      .then((data) => {
        // toasterService.success("Thank you for registering!");
        // updateUserLogs([...data.data]);
        updateUserLogs([...data.data]);
      })
      .catch((error) => {
        toasterService.error(error.response.data.message);
      });
  }


  const approveUser = (prop) => {
    let ipAddressId = prop.row.original.bf_Valid_IP.id;
    let userId = prop.row.original.USR_ID;
    let userApproved = prop.row.original.bf_Valid_IP.Status == "approved" ? true : false;
    if (userApproved) {
      commonService
        .updateUserStatus(userId)
        .then((data) => {
          toasterService.success("User has been disapproved.");
          _getUsers();
        })
        .catch((error) => {
          toasterService.error(error.response.data.message);
        });
      commonService
        .disapproveIPAddress(userId, ipAddressId)
        .then((data) => {
          toasterService.success("IP address has been disapproved.");
          _getUsers();
        })
        .catch((error) => {
          toasterService.error(error.response.data.message);
        });
    }
    else {
      commonService
        .updateUserStatus(userId)
        .then((data) => {
          toasterService.success("User has been approved.");
          _getUsers();
        })
        .catch((error) => {
          toasterService.error(error.response.data.message);
        });
      commonService
        .approveIPAddress(userId, ipAddressId)
        .then((data) => {
          toasterService.success("IP address has been approved.");
          _getUsers();
        })
        .catch((error) => {
          toasterService.error(error.response.data.message);
        });
    }
  };

  // const approveUser = (ev) => {
  //   let ipAddressId = ev.currentTarget.dataset.ip;
  //   let userId = ev.target.id;
  //   let userApproved = ev.currentTarget.dataset.approved;
  //   if (!ev.currentTarget.checked) {
  //     if (userApproved === "false") {
  //       commonService
  //         .updateUserStatus(ev.target.id)
  //         .then((data) => {
  //           toasterService.success("User has been disapproved.");
  //           _getUsers();
  //         })
  //         .catch((error) => {
  //           toasterService.error(error.response.data.message);
  //         });
  //     }
  //     commonService
  //       .disapproveIPAddress(userId, ipAddressId)
  //       .then((data) => {
  //         toasterService.success("IP address has been disapproved.");
  //         _getUsers();
  //       })
  //       .catch((error) => {
  //         toasterService.error(error.response.data.message);
  //       });
  //   } else {
  //     if (userApproved === "false") {
  //       commonService
  //         .updateUserStatus(ev.target.id)
  //         .then((data) => {
  //           toasterService.success("User has been approved.");
  //           _getUsers();
  //         })
  //         .catch((error) => {
  //           toasterService.error(error.response.data.message);
  //         });
  //     }
  //     commonService
  //       .approveIPAddress(userId, ipAddressId)
  //       .then((data) => {
  //         toasterService.success("IP address has been approved.");
  //         _getUsers();
  //       })
  //       .catch((error) => {
  //         toasterService.error(error.response.data.message);
  //       });
  //   }
  // };

  const updateUserRole = (ev) => {
    let data = { Role: ev.target.value };
    let userId = ev.currentTarget.dataset.user;
    commonService
      .updateUser(userId, data)
      .then((data) => {
        toasterService.success("User role has been updated.");
        _getUsers();
      })
      .catch((error) => {
        toasterService.error(error.response.data.message);
      });
  };

  return (
    <div>
      <div className="mb-3">
        <h4>User Approval</h4>
        <p className="text-muted">
          Approve/Reject User IP Addresses to control access to the portal
        </p>
      </div>
      <div className="search-wrapper">
        <i className="bi-search"></i>
        <label htmlFor="search-form">
          <input
            type="search"
            name="search-form"
            id="search-form"
            className="form-control"
            placeholder="Search users"
            value={globalFilter || ""}
            onChange={e => setGlobalFilter(e.target.value)}
          />
        </label>
      </div>
      {/* <Table striped bordered hover>
        <thead>
          <tr>
            {columns.map(({ label, accessor, sortable, width }) => {
              const sortIcon = sortable ? (
                sortField === accessor && order === "asc" ? (
                  <i className="bi bi-sort-down-alt"></i>
                ) : sortField === accessor && order === "desc" ? (
                  <i className="bi bi-sort-down"></i>
                ) : (
                  <i className="bi bi-sort-down-alt"></i>
                )
              ) : (
                ""
              );
              return (
                <th
                  key={accessor}
                  style={{ width: width }}
                  onClick={
                    sortable ? () => handleSortingChange(accessor) : null
                  }
                >
                  {label}
                  {sortIcon}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          <tr hidden={userLogs.length > 0}>
            <td align="center" colSpan="7">
              No records available
            </td>
          </tr>
          {search(userLogs).map((user) => {
            return user.bf_Valid_IP ? (
              <tr key={user.bf_Valid_IP.id}>
                <td>{user.FName}</td>
                <td>{user.LName}</td>
                <td>{user.ORG_Name}</td>
                <td>{user.email}</td>
                <td>{user.bf_Valid_IP.IP_Address}</td>
                <td>
                  <label className="switch ">
                    <input
                      className="success"
                      data-approved={user.Approval_Ind}
                      data-ip={user.bf_Valid_IP.id}
                      id={user.USR_ID}
                      key={user.USR_ID}
                      type="checkbox"
                      defaultChecked={user.bf_Valid_IP.Status == "approved"}
                      onChange={approveUser}
                      name={user.USR_ID}
                    />
                    <span className="slider round"></span>
                  </label>
                </td>
                <td>{new Date(user.bf_Valid_IP.updatedAt).toLocaleString()}</td>
              </tr>
            ) : (
              <tr key={Math.random()}>
                <td>{user.FName}</td>
                <td>{user.LName}</td>
                <td>{user.ORG_Name}</td>
                <td>{user.email}</td>
                <td>Null</td>

              </tr>
            );
          })}
        </tbody>
      </Table> */}
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={
                    column.isSorted
                      ? column.isSortedDesc
                        ? "desc"
                        : "asc"
                      : ""
                  }
                >
                  <span>{column.render('Header')}</span>
                  {column.isSorted
                    ? column.isSortedDesc
                      ? <i className="bi bi-sort-down"></i>
                      : <i className="bi bi-sort-down-alt"></i>
                    : ""}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="pagination-container justify-content-center align-items-center my-4 gap-4">
        <span>
          Page&nbsp;
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <ul className="pagination m-0">
          <li className="page-item" >
            <a disabled={!canPreviousPage} onClick={() => gotoPage(0)} className="page-link"><i className="bi-chevron-double-left"></i></a>
          </li>{" "}
          <li className="page-item" >
            <a onClick={() => previousPage()} disabled={!canPreviousPage} className="page-link"><i className="bi-chevron-left"></i></a>
          </li>{" "}
          <li className="page-item" >
            <a className="page-link" onClick={() => nextPage()} disabled={!canNextPage}><i className="bi-chevron-right"></i></a>
          </li>{" "}
          <li className="page-item">
            <a onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage} className="page-link"><i className="bi-chevron-double-right"></i></a>
          </li>{" "}
        </ul>
        <select
          value={pageSize}
          onChange={(e) => setPageSize(Number(e.target.value))}
        >
          {[10, 50, 100].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
      {/* <div>{JSON.stringify(userLogs)}</div> */}
    </div>
  );
}
