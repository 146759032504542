export const HelperService = {
    isNegative(value) {
        let formattedVal = value.replace('$', '').replace(',', '').replace(' ', '').trim();
        if (Number(formattedVal) < 0) {
            return "Value cannot be less than 0";
        }
        return true;
    }
}

