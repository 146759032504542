import React from "react";
import Input from "./Fields/Input";
import SelectField from "./Fields/SelectField";
import Currency from "./Fields/Currency";
import Percent from "./Fields/Percent";
import Integer from "./Fields/Integer";
import FloatInput from "./Fields/Float";
import DateField from "./Fields/DateField";

export function FormField({
  field: {
    field_type,
    data_type,
    field_id,
    field_label,
    field_placeholder,
    field_value,
    field_options,
    default_value,
    validation,
  },
}) {
  switch (field_type.split(":")[0]) {
    case "text":
      if (data_type == "currency") {
        return (
          <Currency
            field_id={field_id}
            field_label={field_label}
            field_placeholder={field_placeholder}
            field_value={field_value}
            default_value={default_value}
            validation={validation}
          />
        );
      } else if (data_type == "percent") {
        return (
          <Percent
            field_id={field_id}
            field_label={field_label}
            field_placeholder={field_placeholder}
            field_value={field_value}
            default_value={default_value}
            validation={validation}
          />
        );
      } else if (data_type == "integer") {
        return (
          <Integer
            field_id={field_id}
            field_label={field_label}
            field_placeholder={field_placeholder}
            field_value={field_value}
            default_value={default_value}
            validation={validation}
          />
        );
      } else if (data_type == "float") {
        return (
          <FloatInput
            field_id={field_id}
            field_label={field_label}
            field_placeholder={field_placeholder}
            field_value={field_value}
            default_value={default_value}
            validation={validation}
          />
        );
      } else {
        return (
          <Input
            field_id={field_id}
            field_label={field_label}
            field_placeholder={field_placeholder}
            field_value={field_value}
            default_value={default_value}
            validation={validation}
          />
        );
      }

    case "DD":
      return (
        <SelectField
          field_id={field_id}
          field_label={field_label}
          field_placeholder={field_placeholder}
          field_value={field_value}
          field_options={field_options}
          default_value={default_value}
          validation={validation}
        />
      );
    case "date":
      return (
        <DateField
          field_id={field_id}
          field_label={field_label}
          field_value={field_value}
          default_value={default_value}
          validation={validation}
        />
      );

    default:
      return null;
  }
}
