import {
  Container,
  Row,
  Col,
  Form,
  FloatingLabel,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import {
  commonService,
  toasterService,
  authService,
} from "../../services/index";
import { useContext, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { useAuthDispatch } from "../../contexts/context";

export function VerifyOtp({ user, mode }) {
  const history = useHistory();
  const dispatch = useAuthDispatch();

  const validationSchema = Yup.object().shape({
    otp: Yup.string().required("Please enter the pass code"),
    remember_me: Yup.bool(),
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const [userDetails, userDetailsFn] = useState(null);

  useEffect(() => {
    userDetailsFn(user);
  });

  const onSubmit = ({ otp, remember_me }) => {
    if (userDetails) {
      userDetailsFn((userDetails["otp"] = otp));
      if (mode == "SIGNUP") {
        // console.log(userDetails);
        commonService
          .register(userDetails)
          .then((data) => {
            toasterService.success(
              "Your sign-up request has been successfully submitted. We will reach out shortly to let you know when it is approved.",
              {
                hideProgressBar: true,
                autoClose: false,
              }
            );
            history.push("/login");
          })
          .catch((error) => {
            toasterService.error(error.response.data.message);
          });
      } else if (mode == "LOGIN") {
        userDetailsFn((userDetails["remember_me"] = remember_me));
        commonService
          .login(userDetails)
          .then((data) => {
            dispatch({ type: "LOGIN_SUCCESS", payload: data.data });
            sessionStorage.setItem("currentUser", JSON.stringify(data.data));
            // authService.saveToken(data.data.token);
            history.push("/portal");
          })
          .catch((error) => {
            toasterService.error(error.response.data.message);
          });
      }
    }
  };

  const resendOTP = () => {
    commonService
      .login(userDetails)
      .then((data) => {
        toasterService.success("OTP sent successfully");
      })
      .catch((error) => {
        toasterService.error(error.response.data.message);
        // if (error.response.data.message == "Passcode already sent") {
        //     submitUserFn(true);
        // }
      });
  };

  return (
    <>
      <div className="w-100">
        <div className="mb-4">
          <h3>Verify Pass Code</h3>
          <p className="m-0 text-muted">
            <small>A code has been sent to your registered email address</small>
          </p>
        </div>
      </div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="mb-3">
          <Form.Label>Pass Code</Form.Label>
          <Form.Control
            name="otp"
            type="text"
            placeholder="Enter Code"
            {...register("otp")}
          />
          <ErrorMessage
            errors={errors}
            name="otp"
            render={({ message }) => (
              <p className="fb-error-message">{message}</p>
            )}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Button type="submit" className="w-100" variant="primary">
            Verify
          </Button>
        </Form.Group>
        <div className="text-center">
          Did not receive code?{" "}
          <a href="javascript:;" onClick={resendOTP}>
            Resend
          </a>
        </div>
      </Form>
    </>
  );
}
