import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import "./Login.css";
import logo from "../../assets/images/Button_Finance_Logo.png";
import login_bg from "../../assets/images/login-bg.png";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { commonService, toasterService } from "../../services/index";
import { useState } from "react";
import { VerifyOtp } from "../../components/VerifyOtp/VerifyOtp";
import { Role } from "../../helpers";
import { useAuthDispatch } from "../../contexts/context";

export function Login() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const history = useHistory();
  const dispatch = useAuthDispatch();
  const [userSubmitted, submitUserFn] = useState(false);
  const [userDetails, userDetailsFn] = useState({});

  function onSubmit(formObj) {
    userDetailsFn(formObj);
    commonService
      .login(formObj)
      .then((data) => {
        if (data.data.Role == Role.Admin || data.data.token) {
          dispatch({ type: "LOGIN_SUCCESS", payload: data.data });
          sessionStorage.setItem("currentUser", JSON.stringify(data.data));
          history.push("/portal");
        } else {
          submitUserFn(true);
        }
      })
      .catch((error) => {
        toasterService.error(error.response.data.message);
        if (error.response.data.message == "Passcode already sent") {
          submitUserFn(true);
        }
      });
  }

  return (
    <div className="ip-section">
      <Container>
        <Row className="mb-4">
          <Col xs lg="12" className="text-center">
            <img
              src={logo}
              width="140"
              className="d-inline-block align-top"
              alt="Button Finance logo"
            />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <div className="col-lg-10 col-md-12">
            <div className="bf-banner">
              <p>Passwords are no longer needed</p>
            </div>
          </div>
          <Col xs lg="10" md="12">
            <div className="wrap d-md-flex">
              <div
                style={{ backgroundImage: `url(${login_bg})` }}
                className="login-bg"
              ></div>
              <div className="login-wrap p-4 p-md-5">
                {!userSubmitted && (
                  <>
                    <div className="w-100">
                      <h3 className="mb-4">Sign In</h3>
                    </div>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                      <Form.Group
                        className="mb-3"
                        controlId="formPlaintextEmail"
                      >
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          {...register("email", {
                            required: "Please enter your email address",
                          })}
                          type="email"
                          placeholder="Email Address"
                        />
                        <ErrorMessage
                          errors={errors}
                          name="email"
                          render={({ message }) => (
                            <p className="fb-error-message">{message}</p>
                          )}
                        />
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Button
                          type="submit"
                          className="w-100"
                          variant="primary"
                        >
                          Sign in
                        </Button>
                      </Form.Group>
                      <div className="d-flex justify-content-between mb-3">
                        <Form.Group
                          className="mb-3"
                          controlId="formBasicCheckbox"
                        ></Form.Group>
                        <Link to="/forgot-password"></Link>
                      </div>
                      <div className="text-center">
                        New User? <Link to="/signup">Sign Up</Link>
                      </div>
                    </Form>
                  </>
                )}
                {userSubmitted && <VerifyOtp mode="LOGIN" user={userDetails} />}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
