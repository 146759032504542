import React, { useEffect } from 'react'
import $ from 'jquery';
import Inputmask from "inputmask";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

const Percent = ({ field_id, field_label, field_placeholder, field_value, validation, default_value }) => {

    const { register, errors } = useFormContext();
    const validationObj = validation ? JSON.parse(validation) : null;

    useEffect(() => {
        // Update the document title using the browser API
        // console.log("Percent Component loaded");
        Inputmask().mask(document.querySelectorAll("input"));

    });

    const checkMinMaxValue = (value) => {
        var formattedVal = Number(value.replaceAll("%", "")).toFixed(4);
        if (field_id == "annual_hpi_projection") {
            if (formattedVal < validationObj.min_value || formattedVal > validationObj.max_value) {
                return `Value must be between ${validationObj.min_value}% and ${validationObj.max_value}%`;
            }
            return true;
        }
    }


    return (
        <div className="mb-3">
            <label htmlFor={field_id} className="form-label">{field_label}</label>
            <input type="text" className="form-control" id={field_id}
                data-inputmask="'alias': 'numeric', 'groupSeparator': '', 'digits': 4, 'digitsOptional': false, 'suffix': ' %', 'placeholder': '0'"
                inputMode="decimal"
                defaultValue={default_value}
                placeholder={field_placeholder ? field_placeholder : ''}
                key={field_id}
                {...register(field_id, {
                    required: { value: validationObj.required ? validationObj.required : false, message: "This is a required field" },
                    validate: checkMinMaxValue
                })}
            />
            <ErrorMessage
                errors={errors}
                name={field_id}
                render={({ message }) => <p className="fb-error-message">{message}</p>}
            />
            {/* {
                errors[field_id] && errors[field_id]["type"] === "min" && (
                    <div className="error">Your score must be no more than 100</div>
                )
            } */}
        </div>
    )
}

export default Percent