import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import "./PriceLoanPortfolio.css";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { commonService, toasterService } from "../../services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

export function PriceLoanPortfolio() {
  const schema = yup.object().shape({
    document: yup
      .mixed()
      .required("File is required")
      //   .test("fileSize", "The file is too large", (value) => {
      //     return value && value[0].size <= 2000000;
      //   })
      .test("type", "Please upload a valid csv file", (value) => {
        if (!value.length) return false;
        return (
          value &&
          (value[0].type === "text/csv" ||
            value[0].type === "application/vnd.ms-excel")
        );
      }),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [formSuccess, setFormSuccess] = useState(false);

  const onSubmit = (data) => {
    if (data.document && data.document.length > 0) {
      const formData = new FormData();
      formData.append("file", data.document[0]);
      commonService
        .uploadCSV(formData)
        .then((data) => {
          if (data.status == 200) {
            let inputfileName = data.data.file.split(".")[0];
            getOutputReport(inputfileName, 2);
          }
        })
        .catch((error) => {
          toasterService.error(error.response.data.message);
        });
    }
  };

  function getOutputReport(filename, type) {
    commonService
      .generateReport({ file: filename, type: type })
      .then((data) => {
        // toasterService.success(data.data, {
        //     hideProgressBar: true,
        //     autoClose: false
        // });
        setFormSuccess(true);
        //setReportSuccess(reportSuccess => reportSuccess = true);
      })
      .catch((error) => {
        toasterService.error(error.response.data.message);
      });
  }

  return (
    <div className="position-relative">
      <div className="bf-download-btn top-0">
        <a
          href={process.env.PUBLIC_URL + "/loan_file_input_template.csv"}
          download
          className="btn btn-primary"
        >
          Download Loan Input Template
        </a>
      </div>
      <div className="mb-5">
        <h4>Price Loan Portfolio</h4>
        {/* <p className="text-muted">Upload multiple loan tape file</p> */}
      </div>

      <div>
        {formSuccess && (
          <div className="card shadow form-success">
            {/* <FontAwesomeIcon className="text-success fa-4x mb-3" icon={faCheckCircle} /> */}
            <h4 className="display-6 text-center">
              Thank you. Your data is now being processed. The output report
              will be mailed to you on your registered email address.
            </h4>
            {/* <div className="progress mt-4">
                                <div className="progress-bar-fb" style={{ background: "#337ab7" }}></div>
                            </div> */}
          </div>
        )}

        <Form hidden={formSuccess} onSubmit={handleSubmit(onSubmit)}>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Please select a CSV file to upload</Form.Label>
            <input
              type="file"
              className="form-control"
              accept=".csv"
              {...register("document")}
            />
          </Form.Group>
          <ErrorMessage
            errors={errors}
            name="document"
            render={({ message }) => (
              <div className="alert alert-danger mb-3">
                <b>Error: </b> {message}
              </div>
            )}
          />
          <Form.Group className="mb-3">
            <Button type="submit" variant="primary">
              Submit
            </Button>
          </Form.Group>
        </Form>
      </div>
    </div>
  );
}
