import axios from "axios";

function authHeader() {
    const user = JSON.parse(sessionStorage.getItem('currentUser'));

    if (user && user.token) {
        return user.token;
    } else {
        return null;
    }
}

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
    responseType: "json",
});

axiosInstance.interceptors.request.use(
    (config) => {
        const token = authHeader();
        const auth = token ? `Bearer ${token}` : '';
        config.headers.common['Authorization'] = auth;
        return config;
    },
    (error) => Promise.reject(error),
);

export default axiosInstance;